#game-canvas {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
}

#overlay-message {
    position: absolute;
    width: 100%;
    left: 0;
    top: 0;
    background: rgba(255, 255, 255, 0.69);
    text-align: center;
    padding-top: 2px;
    padding-bottom: 2px;
}

#room-code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

#ui-controls-friendly {
    position: absolute;
    right: 15px;
    bottom: 48px;
}

#ui-controls-dangerous {
    position: absolute;
    right: 15px;
    bottom: 15px;
}

label {
    background: rgba(255, 255, 255, 0.69);
}

#sticksPerSide-label {
    font-size: small;
    padding: 1px;
    border-radius: 7px;
}

#sticksPerSide-select {
    margin-left: 5px;
}

#reset-button {
    margin-left: 5px;
}

#reload-button {
    margin-left: 5px;
}

#copy-room-code-button {
    margin-left: 2px;
}

#exit-button {
    margin-left: 5px;
}