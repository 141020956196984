#room-code-dialog {
    margin-top: 30vh;
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 7px;
    text-align: center;
}

#room-code-dialog label {
    background: transparent;
}

#room-code-input {
    margin-top: 5px;
    margin-bottom: 5px;
}

/*original code from: https://codepen.io/chris22smith/pen/RZogMa*/
.bg {
    animation: slide 6s ease-in-out infinite alternate;
    /*using the game balls colors:*/
    background-image: linear-gradient(-69deg, #ffffee 50%, #2a0a0a 33%, #ffffee 100%, #110000 28%);
    bottom: 0;
    left: -50%;
    opacity: 0.5;
    position: fixed;
    right: -50%;
    top: 0;
    z-index: -1;
}

.bg2 {
    animation-direction: alternate-reverse;
    animation-duration: 8s;
}

.bg3 {
    animation-duration: 10s;
}

@keyframes slide {
    0% {
        transform: translateX(-25%);
    }
    100% {
        transform: translateX(25%);
    }
}